import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaGalacticRepublic, FaHornbill, FaReact } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				About | Elite FootGear
			</title>
			<meta name={"description"} content={"Precision Selection for Every Court and Gym"} />
			<meta property={"og:title"} content={"About | Elite FootGear"} />
			<meta property={"og:description"} content={"Precision Selection for Every Court and Gym"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13:08:31.563Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					About Elite FootGear
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					width="75%"
				>
					Discover Elite FootGear's journey and dedication in offering curated football equipment, trainers, and accessories for the market. Learn about our distinct advantages and why we're the trusted choice for many.
					<br />
					<br />
					At Elite FootGear, our mission is simple: to equip arenas with top-tier football equipment, trainers, and accessories, making the process seamless and ensuring quality at every step. Though we're not manufacturers, our discerning eye and meticulous sourcing methods ensure we curate only the finest products for our clientele. Serving exclusively the football market, our commitment is unwavering — to empower and equip every gym and court to be at its absolute best.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaGalacticRepublic}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Curated Excellence
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						While we don't produce, we perfect the art of selection. Only the best products, from trusted brands and proven suppliers, make it to our collection.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaHornbill}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Specialised Focus
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Our football-centric approach means we understand the specific needs and demands of gyms and courts. We're not just selling – we're partners in your success.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaReact}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Transparent Pricing
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						With Elite FootGear, there are no hidden charges or overheads. Our competitive pricing reflects our commitment to delivering value.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13:08:31.563Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13%3A08%3A31.563Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13%3A08%3A31.563Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13%3A08%3A31.563Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13%3A08%3A31.563Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13%3A08%3A31.563Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13%3A08%3A31.563Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13%3A08%3A31.563Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Why Elite FootGear?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Choosing us is an investment in quality, reliability, and partnership. We rise above competitors because we prioritise understanding your unique needs and offering solutions that fit. With Elite FootGear, you're not just buying products – you're forging a relationship with a team that's as passionate about football as you are.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 44px 12px 44px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-indigo"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Visit Us
				</Link>
			</Box>
		</Section>
		<Components.Footer13 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});